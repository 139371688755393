
import { defineComponent, onMounted, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiPriceRules } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "price-rules-information",
  // components: { MBTaggings },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: route.params.id,
      name: "", //非必须
      description: "", //非必须
      from_date: "", // 非必须
      to_date: "", //非必须
      is_active: 0, //非必须 no:0,yes:1
      discard_subsequent_rules: 0, // 非必须 no:0,yes:1
      conditions_serialized: "", //非必须
      sorting: "", //非必须
      action: 0, //非必须 默认值:0, Percent:1,Amount:2
      value: "", // 非必须
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      action: [
        {
          required: true,
          message: "Action is required",
          trigger: "change",
        },
      ],
    });

    const getDistributionPriceRulesData = () => {
      loading.value = true;
      ApiPriceRules.getPriceRulesInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            // taggingValues.value =
            //   data.data.tagging_data.distribution_channel_segmentation.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiPriceRules.updatePriceRules(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const deleteDistributionPriceRules = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Distribution Price Rules?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiPriceRules.deletePriceRules({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Distribution Price Rules has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "distribution-price-rules" });
                });
              } else {
                Swal.fire({
                  title: data.msg,
                  text: data.sub_msg,
                  icon: "error",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    // onBeforeMount(() => {

    // });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      Promise.all([getDistributionPriceRulesData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      loading,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      submit,
      getDistributionPriceRulesData,
      deleteDistributionPriceRules,
    };
  },
});
